import { useDispatch } from 'react-redux';

import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';

import { userReducer } from './user';

export const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  reducer: {
    userState: userReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
