import { environment } from '../environment';

import { MeasurementsGoal, ParametersGoal } from './goals';

export type MetrikaGoal = MeasurementsGoal | ParametersGoal;

export const reachGoal = (goal: MetrikaGoal) => {
  // @ts-expect-error: метрики адекватно не работают с react+ts
  ym(environment.yandexCounterId, 'reachGoal', goal);
};

export const initYm = () => {
  // @ts-expect-error: метрики адекватно не работают с react+ts
  ym(environment.yandexCounterId, 'init', {
    accurateTrackBounce: true,
    trackLinks: true,
  });
};
