import { createAction } from '@reduxjs/toolkit';

import { UserService } from '../../app/services/user.service/user.service';
import { getCreateThunk } from '../../helpers';
import { MeasurementsData } from '../../models/measurement';

export const USER_STORE_NAME = 'user-state';

const createThunk = getCreateThunk(USER_STORE_NAME);

export const initMeasurements = createAction<MeasurementsData>('init measurements');

export const createMeasurements = createThunk('create measurements', UserService.createMeasurements);
