import { EnumType, Option, OptionValue } from '../@types';

export const enumToOptions = <T extends EnumType>(
  enumType: T,
  options?: { getValue?: (key: number | string) => OptionValue; numeric?: boolean }
): Option[] =>
  Object.values(enumType)
    .filter(enumValue => (options?.numeric ? !isNaN(Number(enumValue)) : true))
    .map(
      enumValue =>
        ({
          key: enumValue.toString(),
          value: options?.getValue?.(enumValue) ?? enumValue.toString(),
        }) as Option
    );
