/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef } from 'react';
import { Canvas, useFrame, useThree } from 'react-three-fiber';

import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

interface ModelViewerProps {
  className?: string;
  objPath: string;
}

export const ModelViewer = ({ className, objPath }: ModelViewerProps) => (
  <Canvas className={className}>
    <ambientLight intensity={0.8} />
    <spotLight angle={30} position={[10, 15, 10]} />
    <directionalLight
      color={new THREE.Color(0xffffff)} // Белый цвет
      intensity={1} // Интенсивность света
      position={[
        Math.cos(THREE.MathUtils.degToRad(30)), // X позиция
        Math.sin(THREE.MathUtils.degToRad(30)), // Y позиция
        1, // Z позиция
      ]}
    />
    <ObjModel objPath={objPath} />
  </Canvas>
);

interface ObjModelProps {
  objPath: string;
}

const ObjModel = ({ objPath }: ObjModelProps) => {
  const modelRef = useRef<THREE.Group>();

  const { scene } = useThree();

  useEffect(() => {
    // Загрузчик для .obj файла
    const loader = new OBJLoader();
    loader.load(objPath, object => {
      modelRef.current = object;
      scene.add(object); // Добавляем объект в сцену
      object.rotation.x = 80;
      object.scale.set(3, 3, 3);
    });
  }, [objPath, scene]);

  useFrame(() => {
    // Анимация или обновление модели при необходимости
    if (modelRef.current) {
      modelRef.current.rotation.z += 0.01;
    }
  });

  return null;
};
