import { ChangeEvent, useId } from 'react';

import { Text6 } from '../typography';

import './style.scss';

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({ checked, disabled, label, onChange }: CheckboxProps) => {
  const id = useId();
  return (
    <div className={'checkbox-wrapper'}>
      <input checked={checked} disabled={disabled} id={id} onChange={onChange} type={'checkbox'} />
      <label htmlFor={id}>
        <Text6>{label}</Text6>
      </label>
    </div>
  );
};
