import { ReactNode } from 'react';

import classnames from 'classnames';

import './style.scss';

interface TextProps {
  children: ReactNode | string;
  className?: string;
}

interface TextComponentProps extends TextProps {
  ownClassName: string;
}

const TextComponent = ({ children, className, ownClassName }: TextComponentProps) => {
  return <span className={classnames('default-text', className, ownClassName)}>{children}</span>;
};

export const Text3 = (props: TextProps) => {
  return <TextComponent ownClassName={'text3'} {...props} />;
};

export const Text3M = (props: TextProps) => {
  return <TextComponent ownClassName={'text3-m'} {...props} />;
};

export const Text4 = (props: TextProps) => {
  return <TextComponent ownClassName={'text4'} {...props} />;
};

export const Text5 = (props: TextProps) => {
  return <TextComponent ownClassName={'text5'} {...props} />;
};

export const Text6 = (props: TextProps) => {
  return <TextComponent ownClassName={'text6'} {...props} />;
};

export const Text7 = (props: TextProps) => {
  return <TextComponent ownClassName={'text7'} {...props} />;
};
