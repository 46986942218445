interface Environment {
  baseUrl: string;
  marketId: string;
  yandexCounterId: number;
}

export const environment: Environment = {
  baseUrl: process.env.REACT_APP_API_BASE_URL!,
  marketId: process.env.REACT_APP_MARKET_ID!,
  yandexCounterId: +process.env.REACT_APP_YANDEX_COUNTER_ID!,
};
