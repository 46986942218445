import * as yup from 'yup';

export const userSchema = yup.object().shape({
  bodyType: yup.number().required('Выберите тип фигуры'),
  height: yup
    .number()
    .typeError('Некорректный рост')
    .required('Введите ваш рост')
    .min(80, 'Введите корректный рост')
    .max(250, 'Введите корректный рост'),
  sex: yup.string().required('Выберите пол'),
  typeCloth: yup.string().required('Выберите предпочитаемый тип одежды'),
  weight: yup
    .number()
    .typeError('Некорректный вес')
    .required('Введите ваш вес')
    .min(30, 'Введите корректный вес')
    .max(300, 'Введите корректный вес'),
});
