import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';

import { UserState } from './user.slice';

const selectUserState: (state: RootState) => UserState = state => state.userState;

const selectUserId = createSelector(selectUserState, state => state.userId);
const selectMeasurementsData = createSelector(selectUserState, state => state.measurementsData);

const selectCreateMeasurementsLoading = createSelector(selectUserState, state => state.createMeasurementsLoading);
const selectCreateMeasurementsError = createSelector(selectUserState, state => state.createMeasurementsError);

export const userSelectors = {
  selectCreateMeasurementsError,
  selectCreateMeasurementsLoading,
  selectMeasurementsData,
  selectUserId,
};
