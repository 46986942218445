import React, { CSSProperties, useId } from 'react';

import classNames from 'classnames';

import { Option } from '../../../../@types';
import { Text6 } from '../typography';

import './style.scss';

type Position = 'grid' | 'horizontal' | 'vertical';

interface RadioGroupProps {
  className?: string;
  gridColumnsCount?: number;
  onChange: (value: string) => void;
  options: Option[];
  position?: Position;
  title?: string;
  value?: string;
}

export const RadioGroup = ({
  className,
  gridColumnsCount = 3,
  onChange,
  options,
  position = 'horizontal',
  title,
  value,
}: RadioGroupProps) => {
  const columnsCount = position === 'horizontal' ? options.length : position === 'vertical' ? 1 : gridColumnsCount;
  const rowsCount = Math.ceil(options.length / columnsCount);
  const style = {
    '--radio-group-columns': gridColumnsCount,
  } as CSSProperties;
  return (
    <div className={'radio-group-form'}>
      {title && <Text6>{title}</Text6>}
      <div className={classNames('radio-group', position, className)} style={style}>
        {options.map((option, index) => (
          <RadioGroupItem
            checked={option.key === value}
            className={getRadioButtonClassNames(index, columnsCount, rowsCount)}
            key={option.key}
            onChange={onChange}
            option={option}
          />
        ))}
      </div>
    </div>
  );
};

interface RadioGroupItemProps {
  checked?: boolean;
  className?: string;
  onChange: (value: string) => void;
  option: Option;
}

const RadioGroupItem = ({ checked, className, onChange, option }: RadioGroupItemProps) => {
  const id = useId();

  return (
    <label className={classNames('radio-button', className, { checked })}>
      <input checked={checked} id={id} onChange={() => onChange(option.key)} type={'radio'} />
      {React.isValidElement(option.value) ? option.value : <Text6>{option.value}</Text6>}
    </label>
  );
};

const getRadioButtonClassNames = (index: number, columns: number, rows: number) => {
  const topLeft = index === 0 ? 'top-left' : '';
  const topRight = index === columns - 1 ? 'top-right' : '';
  const bottomLeft = index === columns * (rows - 1) ? 'bottom-left' : '';
  const bottomRight = index === columns * rows - 1 ? 'bottom-right' : '';

  return classNames(topLeft, topRight, bottomLeft, bottomRight);
};
