import classNames from 'classnames';

import { Text3, Text3M } from '../typography';

import './style.scss';

interface LogoProps {
  className?: string;
}

export const Logo = ({ className }: LogoProps) => {
  return (
    <Text3 className={classNames('logo', className)}>
      <Text3M>LEAM</Text3M>SCAN
    </Text3>
  );
};
