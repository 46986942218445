import { ReactNode } from 'react';

import classnames from 'classnames';

import { Loader } from '../loader';

import './style.scss';

interface ButtonProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  styleType?: 'filled' | 'outlined';
  title: string;
}

export const Button = ({
  children,
  className,
  disabled,
  loading,
  onClick,
  styleType = 'filled',
  title,
}: ButtonProps) => {
  return (
    <button
      className={classnames('button', styleType, className)}
      disabled={disabled || loading}
      onClick={onClick}
      title={title}>
      {loading ? <Loader /> : <div className={classnames('button-content', { loading })}>{children}</div>}
    </button>
  );
};
