import { MeasurementsData } from '../../../models/measurement';
import { BaseApiHttpClient } from '../http';

import { CreateMeasurementsRequest } from './requests';

class UserServiceImpl {
  private apiEndpoint = '/user';

  createMeasurements = async (body: CreateMeasurementsRequest): Promise<MeasurementsData> => {
    return await BaseApiHttpClient.post<MeasurementsData>(`${this.apiEndpoint}/measure`, body).then(
      response => response.data
    );
  };
}

export const UserService = new UserServiceImpl();
