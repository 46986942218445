export enum SvgAssetName {
  BodyShapeFemaleHourglass = 'body-shape-female-5',
  BodyShapeFemaleOval = 'body-shape-female-4',
  BodyShapeFemalePear = 'body-shape-female-1',
  BodyShapeFemaleStraight = 'body-shape-female-2',
  BodyShapeFemaleTriangle = 'body-shape-female-3',
  BodyShapeMaleHourglass = 'body-shape-male-5',
  BodyShapeMaleOval = 'body-shape-male-4',
  BodyShapeMalePear = 'body-shape-male-1',
  BodyShapeMaleStraight = 'body-shape-male-2',
  BodyShapeMaleTriangle = 'body-shape-male-3',
}

export interface SvgAssetProps {
  className?: string;
  name: SvgAssetName | string;
}

export const SvgAsset = (props: SvgAssetProps) => {
  return <img alt='' className={props.className} src={`/assets/svg-assets/${props.name}.svg`} />;
};
