import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAppDispatch } from '../store';
import { initMeasurements } from '../store/user';

import { history } from './history';
import { MeasurementsPage } from './pages/MeasurementsPage';
import { ParametersPage } from './pages/ParametersPage';
import { CustomRouter, RoutePath } from './router';
import { MeasurementsDataStorage } from './storages/user.storage/user.storage.service';

import './style.scss';

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const measurements = MeasurementsDataStorage.value;

    if (measurements) {
      dispatch(initMeasurements(measurements));
      history.push(RoutePath.Measurements);
    }
  }, []);

  return (
    <CustomRouter history={history}>
      <Routes>
        <Route element={<ParametersPage />} index path={RoutePath.Parameters} />
        <Route element={<MeasurementsPage />} path={RoutePath.Measurements} />
        <Route element={<Navigate to={RoutePath.Parameters} />} path={'*'} />
      </Routes>
    </CustomRouter>
  );
};
