export enum BodyPart {
  Chest = 'chest',
  Waist = 'waist',
  Hip = 'hip',
}

export const bodyPartLocale = {
  [BodyPart.Chest]: 'Грудь',
  [BodyPart.Hip]: 'Бёдра',
  [BodyPart.Waist]: 'Талия',
};
