import React, { ChangeEvent, ReactNode, useMemo } from 'react';

import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { Text6 } from '../typography';

import './style.scss';

interface InputProps {
  className?: string;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  suffix?: ReactNode | string;
  title?: string;
  value?: number | string;
}

export const Input = ({ className, disabled, onChange, placeholder, suffix, title, value }: InputProps) => {
  const id = useMemo(uuidv4, []);

  return (
    <div className={classnames('input-form', className)}>
      {title && (
        <label htmlFor={id}>
          <Text6>{title}</Text6>
        </label>
      )}
      <div className={'inner-input-container'}>
        <input
          className={'input-content'}
          disabled={disabled}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
        {React.isValidElement(suffix) ? suffix : <Text6 className={'suffix'}>{suffix}</Text6>}
      </div>
    </div>
  );
};
