export enum BodyShape {
  Hourglass = 5,
  Oval = 4,
  Pear = 1,
  Straight = 2,
  Triangle = 3,
}

export const bodyShapeLocale = {
  [BodyShape.Hourglass]: 'Часы',
  [BodyShape.Oval]: 'Овал',
  [BodyShape.Pear]: 'Груша',
  [BodyShape.Straight]: 'Средний',
  [BodyShape.Triangle]: 'Треугольник',
};
