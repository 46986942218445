class StorageService<T> {
  constructor(
    private namespace: string = '',
    private storage: Storage = localStorage
  ) {}

  private buildKey(key: string) {
    return this.namespace ? `${this.namespaceKey}${key}` : key;
  }

  private clearNamespaceStorage() {
    const allKeys = Object.keys(this.storage).filter(k => k.startsWith(this.namespaceKey));

    for (const key of allKeys) {
      this.storage.removeItem(key);
    }
  }

  private get namespaceKey() {
    return `[${this.namespace}]`;
  }

  clear() {
    this.namespace ? this.clearNamespaceStorage() : this.storage.clear();
  }

  getItem(key: string): T | undefined {
    key = this.buildKey(key);

    const value = this.storage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  }

  removeItem(key: string) {
    key = this.buildKey(key);
    this.storage.removeItem(key);
  }

  setItem(key: string, value: T | undefined) {
    key = this.buildKey(key);
    this.storage.setItem(key, JSON.stringify(value));
  }
}

export { StorageService };
