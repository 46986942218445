import { createSlice } from '@reduxjs/toolkit';

import { ErrorPayload } from '../../@types';
import { MeasurementsDataStorage } from '../../app/storages/user.storage/user.storage.service';
import { MeasurementsData } from '../../models/measurement';

import { USER_STORE_NAME, createMeasurements, initMeasurements } from './user.thunks';

export interface UserState {
  addToBucketError?: ErrorPayload;
  addToBucketLoading: boolean;

  createMeasurementsError?: ErrorPayload;
  createMeasurementsLoading: boolean;

  measurementsData?: MeasurementsData;
  userId?: string;
}

const INITIAL_USER_STATE: UserState = {
  addToBucketError: undefined,
  addToBucketLoading: false,

  createMeasurementsError: undefined,
  createMeasurementsLoading: false,

  measurementsData: undefined,
  userId: undefined,
};

const userSlice = createSlice({
  extraReducers: builder =>
    builder
      .addCase(initMeasurements, (state, { payload }) => {
        state.measurementsData = payload;
      })
      .addCase(createMeasurements.pending, state => {
        state.createMeasurementsError = undefined;
        state.createMeasurementsLoading = true;
      })
      .addCase(createMeasurements.fulfilled, (state, { payload }) => {
        state.createMeasurementsLoading = false;
        state.measurementsData = payload;
        MeasurementsDataStorage.value = payload;
      })
      .addCase(createMeasurements.rejected, (state, { payload }) => {
        state.createMeasurementsLoading = false;
        state.createMeasurementsError = payload;
      }),
  initialState: INITIAL_USER_STATE,
  name: USER_STORE_NAME,
  reducers: {},
});

export const userReducer = userSlice.reducer;
