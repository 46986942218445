import { Provider } from 'react-redux';

import ReactDOM from 'react-dom/client';

import { App } from './app';
import { store } from './store';
import { initYm } from './ym';

import './app/styles/colors.scss';
import './app/styles/global-styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
initYm();
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
