import classnames from 'classnames';

import './style.scss';

export enum IconName {
  ArrowLeft = 'arrow-left',
  Close = 'close',
}

export interface IconProps {
  className?: string;
  name: IconName;
  onClick?: () => void;
}

export const Icon = (props: IconProps) => {
  return (
    <svg className={classnames('svg', props.className)} onClick={props.onClick}>
      <use xlinkHref={`/assets/icons.svg#${props.name}`} />
    </svg>
  );
};
