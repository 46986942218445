import { ReactNode } from 'react';

import classNames from 'classnames';

import './style.scss';

interface InfoBlockProps {
  children: ReactNode;
  className?: string;
}
export const InfoBlock = ({ children, className }: InfoBlockProps) => {
  return <div className={classNames('info-block', className)}>{children}</div>;
};
