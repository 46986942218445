import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Option } from '../../../@types';
import { enumToOptions } from '../../../helpers';
import { BodyMeasurement, BodyPart, bodyPartLocale } from '../../../models/measurement';
import { userSelectors } from '../../../store/user';
import { MeasurementsGoal, reachGoal } from '../../../ym';
import { ModelViewer } from '../../components/model-viewer';
import { Button } from '../../components/shared/button';
import { Icon, IconName } from '../../components/shared/icon';
import { InfoBlock } from '../../components/shared/info-block';
import { Loader } from '../../components/shared/loader';
import { Logo } from '../../components/shared/logo';
import { Page } from '../../components/shared/page';
import { RadioGroup } from '../../components/shared/radio-group';
import { Text3M, Text6, Text7 } from '../../components/shared/typography';
import { history } from '../../history';

import './style.scss';

const SHOW_MODEL_DELAY = 3000;

// TODO: CRUTCH удалить таймер
const timeout = (delay: number) => {
  return new Promise(res => setTimeout(res, delay));
};

export const MeasurementsPage = () => {
  const measurementsData = useSelector(userSelectors.selectMeasurementsData);

  const [showModel, setShowModel] = useState<boolean>(false);

  useEffect(() => {
    timeout(SHOW_MODEL_DELAY).then(() => setShowModel(true));
  }, []);

  const addToCartClick = () => {
    reachGoal(MeasurementsGoal.AddToBucketButtonClick);
  };

  const [selectedBodyPart, setSelectedBodyPart] = useState<BodyPart>(BodyPart.Chest);

  const measurementOptions = useMemo<Option[]>(() => {
    return measurementsData
      ? enumToOptions(BodyPart, {
          getValue: key => {
            const bodyPart = key as BodyPart;
            return (
              <MeasurementRow
                bodyPart={bodyPart}
                checked={selectedBodyPart === bodyPart}
                key={key}
                measurement={measurementsData[bodyPart]}
              />
            );
          },
        })
      : [];
  }, [measurementsData, selectedBodyPart]);

  return (
    <Page>
      <div className={'measurements-page'}>
        <div className={'measurements-page-header'}>
          <Icon name={IconName.ArrowLeft} onClick={history.back} />
          <Logo />
          <Icon name={IconName.Close} />
        </div>
        <Text3M className={'title'}>Шаг 2. Ваши мерки</Text3M>
        <InfoBlock>
          <Text7>Мы сделали прогноз по вашей фигуре и измерениям на основе предоставленных данных</Text7>
        </InfoBlock>
        {showModel && measurementsData?.model ? (
          <ModelViewer className={'scene-model'} objPath={measurementsData.model} />
        ) : (
          <div className={'model-loader-container'}>
            <Loader className={'model-loader'} />
          </div>
        )}
        <InfoBlock>
          <Text7>Выберите по какому из показателей добавить товар в корзину</Text7>
        </InfoBlock>
        <RadioGroup
          className={'body-part-radio-group'}
          onChange={value => setSelectedBodyPart(value as BodyPart)}
          options={measurementOptions}
          position={'vertical'}
          value={selectedBodyPart}
        />
        <Button onClick={addToCartClick} title='add'>
          <Text3M>{`Добавить в корзину размер ${measurementsData?.[selectedBodyPart].euSize}`}</Text3M>
        </Button>
      </div>
    </Page>
  );
};

interface MeasurementRowProps {
  bodyPart: BodyPart;
  checked: boolean;
  measurement: BodyMeasurement;
}

const MeasurementRow = ({ bodyPart, checked, measurement }: MeasurementRowProps) => {
  return (
    <div className={'measurement-row-container'}>
      {checked && <Text6>Выбрано</Text6>}
      <div className={'measurement-row'}>
        <Text3M>{bodyPartLocale[bodyPart]}</Text3M>
        {measurement.euSize && (
          <div className={'measurement-item'}>
            <Text7>{'EU'}</Text7>
            <Text3M>{measurement.euSize}</Text3M>
          </div>
        )}
        {measurement.ruSize && (
          <div className={'measurement-item'}>
            <Text7>{'RU'}</Text7>
            <Text3M>{measurement.ruSize}</Text3M>
          </div>
        )}
        <div className={'measurement-item'}>
          <Text7>{'Обхват'}</Text7>
          <Text3M>{`${measurement.value} см`}</Text3M>
        </div>
      </div>
    </div>
  );
};
