import { useEffect } from 'react';

import { Action, UnknownAction } from '@reduxjs/toolkit';

import { listenerMiddleware } from '../../store';

export const useActionListener = (effect: (action: UnknownAction) => void) => {
  useEffect(() => {
    const unsubscribe = listenerMiddleware.startListening({
      effect,
      predicate: (action): action is Action => true,
    });

    return () => {
      unsubscribe();
      listenerMiddleware.clearListeners();
    };
  }, [effect]);
};
