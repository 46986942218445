import { MeasurementsData } from '../../../models/measurement';
import { KeyStorageService } from '../key-storage.service';

const USER_STORAGE_NAMESPACE = 'user';

export const UserIdStorage = new KeyStorageService<string>(USER_STORAGE_NAMESPACE, 'id');
export const MeasurementsDataStorage = new KeyStorageService<MeasurementsData>(
  USER_STORAGE_NAMESPACE,
  'measurements-data'
);
