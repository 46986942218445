import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';

import { Option } from '../../../@types';
import { environment } from '../../../environment';
import { enumToOptions } from '../../../helpers';
import { BodyShape, ClothSex, ClothSize, ClothType, Sex, bodyShapeLocale } from '../../../models/measurement';
import { useAppDispatch } from '../../../store';
import { createMeasurements, userSelectors } from '../../../store/user';
import { ParametersGoal, reachGoal } from '../../../ym';
import { Button } from '../../components/shared/button';
import { Checkbox } from '../../components/shared/checkbox';
import { InfoBlock } from '../../components/shared/info-block';
import { Input } from '../../components/shared/input';
import { Logo } from '../../components/shared/logo';
import { Page } from '../../components/shared/page';
import { RadioGroup } from '../../components/shared/radio-group';
import { SvgAsset } from '../../components/shared/svg-asset';
import { Text3M, Text6, Text7 } from '../../components/shared/typography';
import { history } from '../../history';
import { useActionListener } from '../../hooks';
import { RoutePath } from '../../router';
import { CreateMeasurementsRequest } from '../../services/user.service/requests/create-measurements.request';
import { userSchema } from '../../validation';

import './style.scss';

interface Values {
  bodyType?: BodyShape;
  height: string;
  sex?: Sex;
  size?: ClothSize;
  typeCloth?: ClothType;
  weight: string;
}

const initialValues: Values = {
  bodyType: undefined,
  height: '',
  sex: undefined,
  size: undefined,
  typeCloth: undefined,
  weight: '',
};

export const ParametersPage = () => {
  const dispatch = useAppDispatch();
  const createMeasurementsError = useSelector(userSelectors.selectCreateMeasurementsError);

  const [userPersonalDataAgreed, setUserPersonalDataAgreed] = useState<boolean>(false);
  const [bmi, setBmi] = useState<number>();

  useActionListener(action => {
    if (action.type === createMeasurements.fulfilled.type) {
      history.push(RoutePath.Measurements);
    }
  });

  const sexOptions = useMemo<Option[]>(
    () => [
      { key: Sex.Male, value: 'Мужской' },
      { key: Sex.Female, value: 'Женский' },
    ],
    []
  );

  const sizeOptions = useMemo<Option[]>(
    () => [
      { key: ClothSize.Xs, value: ClothSize.Xs },
      { key: ClothSize.S, value: ClothSize.S },
      { key: ClothSize.M, value: ClothSize.M },
      { key: ClothSize.L, value: ClothSize.L },
      { key: ClothSize.Xl, value: ClothSize.Xl },
      { key: ClothSize.Xxl, value: ClothSize.Xxl },
    ],
    []
  );

  const clothTypeOptions = useMemo<Option[]>(
    () => [
      { key: ClothType.Loose, value: 'Свободный' },
      { key: ClothType.Fitted, value: 'Приталенный' },
    ],
    []
  );

  const { errors, handleChange, handleSubmit, setFieldValue, values } = useFormik({
    initialValues,
    onSubmit: values => {
      if (!values.bodyType) {
        return;
      }

      const request: CreateMeasurementsRequest = {
        bodyType: values.bodyType,
        //TODO: Временное решение отправки clothSex, в будущем будет отправляться действительное значение
        clothSex: ClothSex.Woman,
        height: +values.height,
        market: environment.marketId,
        sex: values.sex === Sex.Male,
        size: values.size,
        typeCloth: values.typeCloth === ClothType.Loose,
        weight: +values.weight,
      };
      dispatch(createMeasurements(request));
      reachGoal(ParametersGoal.ContinueButtonClick);
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: userSchema,
  });

  useEffect(() => {
    if (values.weight && values.height) setBmi(+values.weight / (+values.height * +values.height));
  }, [values.height, values.weight]);

  const bodyShapeOptions = useMemo<Option[]>(
    () =>
      enumToOptions(BodyShape, {
        getValue: bodyShape => (
          <div className={'body-shape-radio'}>
            <SvgAsset
              className={'image'}
              name={`body-shape-${values.sex === Sex.Male ? 'male' : 'female'}-${bodyShape}`}
            />
            <Text6>{bodyShapeLocale[bodyShape as BodyShape]}</Text6>
          </div>
        ),
        numeric: true,
      }).filter(option =>
        bmi && bmi < 0.0025 ? option.key !== BodyShape.Oval.toString() : option.key !== BodyShape.Hourglass.toString()
      ),
    [bmi, values.sex]
  );

  return (
    <Page>
      <div className={'parameters-page'}>
        <Logo className={'parameters-page-logo'} />
        <div className={'your-parameters-header'}>
          <Text3M>Шаг 1. Ваши параметры</Text3M>
          <InfoBlock>
            <Text7>Введите параметры, чтобы мы могли точно определить подходящий для вас размер</Text7>
          </InfoBlock>
        </div>
        <div className='inputs'>
          <Input
            onChange={e => handleChange('height')(e.target.value)}
            suffix='см'
            title='Рост'
            value={values.height}
          />
          <Input onChange={e => handleChange('weight')(e.target.value)} suffix='кг' title='Вес' value={values.weight} />
        </div>
        <RadioGroup onChange={handleChange('sex')} options={sexOptions} title={'Пол'} value={values.sex} />
        <RadioGroup
          onChange={handleChange('size')}
          options={sizeOptions}
          position={'grid'}
          title={'Предпочитаемый размер одежды'}
          value={values.size}
        />
        <RadioGroup
          onChange={handleChange('typeCloth')}
          options={clothTypeOptions}
          title={'Выберите предпочитаемый тип одежды'}
          value={values.typeCloth}
        />
        <RadioGroup
          onChange={value => setFieldValue('bodyType', +value as BodyShape)}
          options={bodyShapeOptions}
          title={'Тип фигуры'}
          value={values.bodyType?.toString()}
        />
        <Text6 className='errors'>
          {Object.values(errors)
            .concat(createMeasurementsError?.message || [])
            .join(',\n')}
        </Text6>
        <div className={'your-parameters-footer'}>
          <Checkbox
            checked={userPersonalDataAgreed}
            //TODO: У лейбла нужно сделать нормальный перенос строки если не влазиет.
            label={'Даю согласие на обработку данных...'}
            onChange={e => setUserPersonalDataAgreed(e.target.checked)}
          />
          <Button disabled={!userPersonalDataAgreed} onClick={handleSubmit} title='Продолжить'>
            <Text3M>Продолжить</Text3M>
          </Button>
        </div>
      </div>
    </Page>
  );
};
