/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorPayload } from '../@types';

type PayloadCreator<Requested, Returned> = (body: Requested, thunkApi: any) => Promise<Returned>;

type AsyncThunkConfig = {
  dispatch?: Dispatch;
  extra?: string;
  fulfilledMeta?: string;
  pendingMeta?: string;
  rejectValue: ErrorPayload;
  rejectedMeta?: ErrorPayload;
  serializedErrorType?: string;
};

export const getCreateThunk =
  (storeName: string) =>
  <Requested, Returned>(actionName: string, func: PayloadCreator<Requested, Returned>) =>
    createAsyncThunk<Returned, Requested, AsyncThunkConfig>(
      createActionName(storeName, actionName),
      async (body: Requested, thunkApi: any): Promise<Returned> => {
        try {
          return await func(body, thunkApi);
        } catch (error) {
          return thunkApi.rejectWithValue(error);
        }
      }
    );

const createActionName = (storeName: string, actionName: string) => {
  return `[${storeName}] ${actionName}`;
};
